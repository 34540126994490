import React, { useMemo, useState, useEffect } from 'react';
import { FlexRow, Heading, Button, DropdownList, FlexColumn, SplitButton } from '@forecast-it/design-system';
import ChangeViewButton from '../../forecast-app/shared/components/changeview/ChangeViewButton';
import { useEyeOptions } from '../../forecast-app/shared/hooks/useEyeOptions';
import { MODAL_TYPE, showModal } from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import { PhaseCard } from './PhaseDeliverablesCard';
import Styled from 'styled-components/macro';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import { getInitialBaselineItems, getInitialPhaseDeliverables } from './Util';
import { useHistory } from 'react-router-dom';
import { projectUrl } from '../../directApi';
import { createProjectUpdateToast } from '../../components/new-ui/project/project-budget-v3/util/BudgetUtils';
import { subscribe, EVENT_ID, unsubscribe } from '../../containers/event_manager';
import { LoadMore } from '../../components/loaders/LoadMore';
import FinancialCalculationTrigger, { financialCalculationTriggerQuery, } from '../../components/new-ui/project/project-budget-v3/loaders/FinancialCalculationTrigger';
const DeliverablesPhasesSectionWrapper = Styled.div `
	padding: 0 32px;
`;
const StyledHeaderRow = Styled.div `
	padding: 8px 0;
`;
export const PHASE_COLUMNS = {
    TIME_ESTIMATES: 'time_estimates',
    EXPENSE_ESTIMATES: 'expense_estimates',
    VALUE_OF_SERVICE: 'value_of_service',
    REVENUE: 'revenue',
    COST: 'cost',
    MARKUP: 'markup',
    PROFIT: 'profit',
    MARGIN: 'margin',
    RATE_PER_HOUR: 'rate_per_hour',
    COST_PER_HOUR: 'cost_per_hour',
};
const TIME_ESTIMATES_COLUMNS = [
    PHASE_COLUMNS.VALUE_OF_SERVICE,
    PHASE_COLUMNS.REVENUE,
    PHASE_COLUMNS.COST,
    PHASE_COLUMNS.PROFIT,
    PHASE_COLUMNS.RATE_PER_HOUR,
    PHASE_COLUMNS.COST_PER_HOUR,
];
const EXPENSE_ESTIMATES_COLUMNS = [
    PHASE_COLUMNS.REVENUE,
    PHASE_COLUMNS.COST,
    PHASE_COLUMNS.MARKUP,
    PHASE_COLUMNS.PROFIT,
    PHASE_COLUMNS.MARGIN,
];
const EYE_OPTION_COLUMNS = [
    { id: PHASE_COLUMNS.TIME_ESTIMATES, nestedOptions: TIME_ESTIMATES_COLUMNS },
    { id: PHASE_COLUMNS.EXPENSE_ESTIMATES, nestedOptions: EXPENSE_ESTIMATES_COLUMNS },
];
const PHASE_EYE_OPTIONS = EYE_OPTION_COLUMNS.map(column => ({
    name: column.id,
    translationId: 'phase_management.column.' + column.id,
    disabled: false,
    checked: true,
    nestedOptions: column.nestedOptions.map(nestedColumn => ({
        name: nestedColumn,
        translationId: 'phase_management.column.' + nestedColumn,
        disabled: false,
        checked: true,
    })),
}));
export const DeliverablesPhasesSection = ({ intl, project, company, pageRetry }) => {
    var _a, _b, _c;
    const history = useHistory();
    const currency = ((project === null || project === void 0 ? void 0 : project.rateCard) ? project === null || project === void 0 ? void 0 : project.rateCard.currency : company === null || company === void 0 ? void 0 : company.currency) || '';
    const navigateToBaselineEdit = () => {
        history.push(`${projectUrl(project === null || project === void 0 ? void 0 : project.companyProjectId, project === null || project === void 0 ? void 0 : project.customProjectId)}/baseline/edit`);
    };
    const [retryCallback, setRetryCallback] = useState();
    const [retryProcessing, setRetryProcessing] = useState(false);
    const [retryProps, setRetryProps] = useState();
    const [financialCalculationTriggerTimestamp, setFinancialCalculationTriggerTimestamp] = useState();
    const updateProject = props => {
        if (retryCallback) {
            retryCallback();
            setRetryProps(props);
            setRetryProcessing(true);
        }
    };
    const handleRetryCompletion = result => {
        if (!result.isSmallProject && !(retryProps === null || retryProps === void 0 ? void 0 : retryProps.noToast)) {
            createProjectUpdateToast(intl, result.duration);
        }
    };
    const handleFinancialCalculationTriggerResponse = (retry, result) => {
        if (!retryCallback) {
            setRetryCallback(() => retry);
        }
        if (retryProcessing && result.timestamp !== financialCalculationTriggerTimestamp) {
            handleRetryCompletion(result);
            setRetryProcessing(false);
        }
    };
    useEffect(() => {
        if (sessionStorage.getItem('baselineProjectUpdate') === 'true') {
            sessionStorage.removeItem('baselineProjectUpdate');
            setRetryProcessing(true);
        }
        subscribe(EVENT_ID.BUDGET_UPDATE_PROJECT, updateProject);
        return () => {
            unsubscribe(EVENT_ID.BUDGET_UPDATE_PROJECT, updateProject);
        };
    });
    const phases = ((_a = project === null || project === void 0 ? void 0 : project.phases) === null || _a === void 0 ? void 0 : _a.edges) || [];
    const initialBaselineItems = useMemo(() => getInitialBaselineItems(project), [(_b = project === null || project === void 0 ? void 0 : project.phaseBaselineRoles) === null || _b === void 0 ? void 0 : _b.edges]);
    const initialPhaseDeliverables = useMemo(() => getInitialPhaseDeliverables(project), [(_c = project === null || project === void 0 ? void 0 : project.phaseBaselineRoles) === null || _c === void 0 ? void 0 : _c.edges]);
    const placeholderContextMenuOptions = [
        {
            name: 'option 1',
            onClick: () => {
                return;
            },
        },
        {
            name: 'option 2',
            onClick: () => {
                return;
            },
        },
        {
            name: 'option 3',
            onClick: () => {
                return;
            },
        },
    ];
    const EYE_OPTIONS_LOCALSTORAGE_KEY = 'phase-management-eye-options';
    const [eyeOptionMap, setEyeOptionMap] = useState();
    const [, , eyeOptions, handleEyeOptionsChange] = useEyeOptions(PHASE_EYE_OPTIONS, EYE_OPTIONS_LOCALSTORAGE_KEY, undefined, setEyeOptionMap);
    const openShowHideColumnsModal = () => {
        showModal({
            type: MODAL_TYPE.SHOW_HIDE_COLUMNS,
            eyeOptions,
            onEyeOptionsChange: handleEyeOptionsChange,
        });
    };
    return (React.createElement(LoadMore, { key: "financial-calculation-trigger", query: financialCalculationTriggerQuery, variables: {
            projectId: project.id,
        }, loader: React.createElement(FinancialCalculationTrigger, null) }, (result, financialTriggerRetry) => {
        setFinancialCalculationTriggerTimestamp(result.timestamp);
        handleFinancialCalculationTriggerResponse(financialTriggerRetry, result);
        if (!financialCalculationTriggerTimestamp || financialCalculationTriggerTimestamp !== result.timestamp) {
            pageRetry();
        }
        return (React.createElement(CustomScrollDiv, { horizontalcontent: true },
            React.createElement(DeliverablesPhasesSectionWrapper, null,
                React.createElement(StyledHeaderRow, null,
                    React.createElement(FlexRow, { justifyContent: 'space-between', gap: 's' },
                        React.createElement(Heading, { size: 4 }, intl.formatMessage({ id: 'baseline.work_items' })),
                        React.createElement(FlexRow, { justifyContent: 'flex-end' },
                            React.createElement(Button, { emphasis: 'medium', icon: 'download', rightIcon: 'chevronDown', onClick: () => {
                                    return undefined;
                                }, "data-cy": 'export-expenses-csv' }),
                            React.createElement(ChangeViewButton, null,
                                React.createElement(DropdownList.ContentGroup, { title: intl.formatMessage({ id: 'change_view.table_visibility' }) },
                                    React.createElement(DropdownList.Item, { onClick: openShowHideColumnsModal }, intl.formatMessage({ id: 'change_view.show_hide_columns' })))),
                            React.createElement(SplitButton, null,
                                React.createElement(SplitButton.Section, { text: intl.formatMessage({ id: 'baseline.edit_baseline.title' }), "data-cy": 'export-phases', emphasis: 'high', onClick: navigateToBaselineEdit }),
                                React.createElement(SplitButton.Section, { icon: 'chevronDown', emphasis: 'high' }))))),
                React.createElement(FlexColumn, { gap: "m" }, phases
                    .map(phaseEdge => {
                    if (phaseEdge && phaseEdge.node && company) {
                        const phase = phaseEdge.node;
                        const items = initialBaselineItems
                            .filter(item => item.phaseId === phase.id)
                            .map((item, index) => (Object.assign(Object.assign({}, item), { index })));
                        return (React.createElement(PhaseCard, { phase: phase, currency: currency, contextMenuOptions: placeholderContextMenuOptions, company: company, phaseBaselineItems: items, eyeOptionMap: eyeOptionMap, projectPhaseDeliverables: initialPhaseDeliverables }));
                    }
                    return null;
                })
                    .filter(Boolean)))));
    }));
};
