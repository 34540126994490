/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
    }, v3 = [
        (v0 /*: any*/),
        (v1 /*: any*/)
    ], v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "baselineCost",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "baselineProfit",
        "storageKey": null
    }, v6 = [
        (v0 /*: any*/)
    ], v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rate",
        "storageKey": null
    }, v8 = [
        {
            "kind": "Literal",
            "name": "convertToProjectCurrency",
            "value": true
        }
    ], v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "baselineMinutes",
        "storageKey": null
    }, v10 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "baselineRevenue",
        "storageKey": null
    }, v11 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "baselineTimeAndExpenses",
        "storageKey": null
    }, v12 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "baselineMargin",
        "storageKey": null
    }, v13 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    }, v14 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
    }, v15 = {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v16 = [
        (v1 /*: any*/),
        (v0 /*: any*/)
    ], v17 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "globalPhaseId",
        "storageKey": null
    }, v18 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deliverableId",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "projectId",
                "type": "String"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "connection": [
                {
                    "count": null,
                    "cursor": null,
                    "direction": "forward",
                    "path": null
                },
                {
                    "count": null,
                    "cursor": null,
                    "direction": "forward",
                    "path": [
                        "project",
                        "phases"
                    ]
                }
            ]
        },
        "name": "BaselineEditPage_viewer",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    (v1 /*: any*/),
                    (v2 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "RoleTypeConnection",
                        "kind": "LinkedField",
                        "name": "roles",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "RoleTypeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Role",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": (v3 /*: any*/),
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ExpenseCategoryTypeConnection",
                        "kind": "LinkedField",
                        "name": "expenseCategories",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "ExpenseCategoryTypeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ExpenseCategory",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                            (v0 /*: any*/),
                                            (v1 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "disabled",
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Variable",
                        "name": "id",
                        "variableName": "projectId"
                    }
                ],
                "concreteType": "ProjectType",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "useBaseline",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "useDeliverables",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "budgetType",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "budget",
                        "storageKey": null
                    },
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "companyProjectId",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "customProjectId",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectColor",
                        "storageKey": null
                    },
                    (v1 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "baselineEstimationMinutesPerDay",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectStartYear",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectStartMonth",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectStartDay",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectEndYear",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectEndMonth",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectEndDay",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "baselineTargetMinutes",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "baselineTarget",
                        "storageKey": null
                    },
                    (v4 /*: any*/),
                    (v5 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "baselineAdjustPercentage",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "baselineAdjustPrice",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "baselineTargetPrice",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "useFixedPriceForBaselineRevenue",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "RateCard",
                        "kind": "LinkedField",
                        "name": "rateCard",
                        "plural": false,
                        "selections": [
                            (v0 /*: any*/),
                            (v2 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Role",
                                "kind": "LinkedField",
                                "name": "disabledRoles",
                                "plural": true,
                                "selections": (v6 /*: any*/),
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "RateTypeConnection",
                                "kind": "LinkedField",
                                "name": "rates",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "RateTypeEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Rate",
                                                "kind": "LinkedField",
                                                "name": "node",
                                                "plural": false,
                                                "selections": [
                                                    (v7 /*: any*/),
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "defaultRate",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "Role",
                                                        "kind": "LinkedField",
                                                        "name": "role",
                                                        "plural": false,
                                                        "selections": (v6 /*: any*/),
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": (v8 /*: any*/),
                        "concreteType": "FinancialNumbers",
                        "kind": "LinkedField",
                        "name": "financialNumbers",
                        "plural": false,
                        "selections": [
                            (v9 /*: any*/),
                            (v10 /*: any*/),
                            (v11 /*: any*/),
                            (v4 /*: any*/),
                            (v5 /*: any*/),
                            (v12 /*: any*/)
                        ],
                        "storageKey": "financialNumbers(convertToProjectCurrency:true)"
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "DeliverableTypeConnection",
                        "kind": "LinkedField",
                        "name": "deliverables",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "DeliverableTypeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "DeliverableType",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": (v3 /*: any*/),
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": "phases",
                        "args": null,
                        "concreteType": "PhaseTypeConnection",
                        "kind": "LinkedField",
                        "name": "__Project_phases_connection",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "PhaseTypeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "PhaseType",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                            (v0 /*: any*/),
                                            (v1 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "startYear",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "startMonth",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "startDay",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "deadlineDay",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "deadlineMonth",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "deadlineYear",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "projectId",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "RoleRate",
                                                "kind": "LinkedField",
                                                "name": "averageRatesByRole",
                                                "plural": true,
                                                "selections": [
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "roleId",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "costRate",
                                                        "storageKey": null
                                                    },
                                                    (v7 /*: any*/)
                                                ],
                                                "storageKey": null
                                            },
                                            {
                                                "alias": "phaseDeliverables",
                                                "args": null,
                                                "concreteType": "PhaseDeliverableTypeConnection",
                                                "kind": "LinkedField",
                                                "name": "__Phase_phaseDeliverables_connection",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "PhaseDeliverableTypeEdge",
                                                        "kind": "LinkedField",
                                                        "name": "edges",
                                                        "plural": true,
                                                        "selections": [
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "concreteType": "PhaseDeliverable",
                                                                "kind": "LinkedField",
                                                                "name": "node",
                                                                "plural": false,
                                                                "selections": [
                                                                    (v0 /*: any*/),
                                                                    {
                                                                        "alias": null,
                                                                        "args": null,
                                                                        "kind": "ScalarField",
                                                                        "name": "phaseId",
                                                                        "storageKey": null
                                                                    },
                                                                    {
                                                                        "alias": null,
                                                                        "args": null,
                                                                        "concreteType": "DeliverableType",
                                                                        "kind": "LinkedField",
                                                                        "name": "deliverable",
                                                                        "plural": false,
                                                                        "selections": (v3 /*: any*/),
                                                                        "storageKey": null
                                                                    },
                                                                    {
                                                                        "alias": null,
                                                                        "args": (v8 /*: any*/),
                                                                        "concreteType": "FinancialNumbers",
                                                                        "kind": "LinkedField",
                                                                        "name": "financialNumbers",
                                                                        "plural": false,
                                                                        "selections": [
                                                                            (v9 /*: any*/),
                                                                            (v11 /*: any*/),
                                                                            (v10 /*: any*/),
                                                                            (v4 /*: any*/),
                                                                            (v5 /*: any*/),
                                                                            (v12 /*: any*/)
                                                                        ],
                                                                        "storageKey": "financialNumbers(convertToProjectCurrency:true)"
                                                                    },
                                                                    (v13 /*: any*/)
                                                                ],
                                                                "storageKey": null
                                                            },
                                                            (v14 /*: any*/)
                                                        ],
                                                        "storageKey": null
                                                    },
                                                    (v15 /*: any*/)
                                                ],
                                                "storageKey": null
                                            },
                                            (v13 /*: any*/)
                                        ],
                                        "storageKey": null
                                    },
                                    (v14 /*: any*/)
                                ],
                                "storageKey": null
                            },
                            (v15 /*: any*/)
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "PhaseBaselineExpenseTypeConnection",
                        "kind": "LinkedField",
                        "name": "phaseBaselineExpenses",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "PhaseBaselineExpenseTypeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "PhaseBaselineExpenseType",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                            (v0 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "expenseName",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "ExpenseCategory",
                                                "kind": "LinkedField",
                                                "name": "expenseCategory",
                                                "plural": false,
                                                "selections": (v16 /*: any*/),
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "expenseRevenue",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "expenseCost",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "expenseMarkup",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "expenseProfit",
                                                "storageKey": null
                                            },
                                            (v17 /*: any*/),
                                            (v18 /*: any*/)
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "PhaseBaselineRoleTypeConnection",
                        "kind": "LinkedField",
                        "name": "phaseBaselineRoles",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "PhaseBaselineRoleTypeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "PhaseBaselineRoleType",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                            (v0 /*: any*/),
                                            (v17 /*: any*/),
                                            (v18 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Role",
                                                "kind": "LinkedField",
                                                "name": "role",
                                                "plural": false,
                                                "selections": (v16 /*: any*/),
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": (v8 /*: any*/),
                                                "concreteType": "FinancialNumbers",
                                                "kind": "LinkedField",
                                                "name": "financialNumbers",
                                                "plural": false,
                                                "selections": [
                                                    (v9 /*: any*/),
                                                    (v11 /*: any*/),
                                                    (v10 /*: any*/),
                                                    (v4 /*: any*/),
                                                    (v5 /*: any*/),
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "baselineRatePerHour",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "baselineCostPerHour",
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": "financialNumbers(convertToProjectCurrency:true)"
                                            }
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "ProjectIndicator_project"
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Viewer"
    };
})();
node.hash = '496272dd1653ca0fddc4bc6131e68955';
export default node;
